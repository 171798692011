/**
 * Created by gigabyte on 11/17/17.
 */

$(function () {
    
    $.widget("movimax.schedule", {
        options: {
            date: new Date()
        },
        _create: function () {
            var opt     = this.options;
            var $elm    = this.element;
            var date    = new Date();
            var currentOptions     = {};
            const TITLE_LIMIT      = 15;
            const TITLE_LIMIT_VERY = 20;
           
            var requestOptions  = function(callback){
                const path      = "/guest/showtime/getoptions";
                const parameter = {};
                request(path, parameter, callback);
            };

            var renderOption = function (title, currentSelection, options, $parent) {
                var $ctrl = $("<div></div>").addClass("control").appendTo($parent);

                var $select = $("<select></select>")
                    .attr("id", "select-" + title)
                    .addClass("form-control form-studio")
                    .appendTo($ctrl);

                
                // setter data
                for (var i = 0; i < options.length; i++) {
                    var val = options[i].id;
                    var text = options[i].text;
                    var $opt = $("<option></option>");
                    $opt.data("value", options[i]);
                    $opt.text(text);
                    $opt.attr("value", val);
                    $opt.appendTo($select);
                };

                // set current selection
                $select.val(currentSelection);

                // getter data
                $select.unbind().change(function () {
                    var selectedDate    = $(this).find(":selected").val();
                    $(this).val(selectedDate.toString());
                    return render(selectedDate, currentOptions);
                });
            };

            var requestSchedule = function(date, callback){
                var url         = "/guest/showtime/showschedule"
                var params      = {};
                params.date     = new Date(date);
                request(url, params, function(reply){
                    if(reply && reply.error) {
                        console.log(reply);
                        return callback(reply);
                    }
                    return callback(reply);
                });
            };  

            var setLoader       = function(isLoading, $parent){
                if(isLoading){
                    var $loaderContainer    = $("<div></div>").addClass("loader-container").appendTo($parent);
                    var $span               = $("<span></span>").text("Loading, please wait.....").appendTo($loaderContainer);
                    var $iconContainer      = $("<div></div>").addClass("icon-container").appendTo($loaderContainer);
                    var $icon               = $("<i></i>").addClass("fa fa-circle-o-notch fa-spin fa-3x fa-fw").appendTo($iconContainer);
                } else {
                    $parent.find(".loader-container").remove();
                }
            };

            var renderNullDate = function($parent){
                var $div = $("<div></div>").attr("style", "text-align: center; padding: 100px 0;").appendTo($parent)
                var $h6 = $("<h6></h6>").addClass("info main").text("Maaf, belum ada film yang dapat dibooking sementara ini. Harap kembali nanti.").appendTo($div);
                var $h6 = $("<h6></h6>").addClass("info sub").attr("style","font-style: italic;").text("Sorry, no movie is currently available for booking. Please come back later").appendTo($div);
            }

            var render = function (date, options) {

                // clear all
                $elm.html("");

                var dateOptions     = options.dates;
                var $row            = $("<div></div>").addClass("row selector-denpasar margin-0").appendTo($elm);

                var $col4           = $("<div></div>").addClass("col-md-4").appendTo($row);
                renderOption("date", date, dateOptions, $col4);

                var $col8           = $("<div></div>").addClass("col-md-8").appendTo($row);
                var dateLabelText   = date ? moment(date).format("dddd, DD MMMM YYYY") : "HARAP PILIH TANGGAL";
                dateLabelText       = dateLabelText.toUpperCase();
                $("<span></span>").addClass("control-label pull-right").text(dateLabelText).appendTo($col8);

                var $row            = $("<div></div>").addClass("row margin-0").appendTo($elm);
                var $col12          = $("<div></div>").addClass("col-lg-12 padding-0").appendTo($row);

                var $idnnotice         = $("<h6></H6>").text("Seluruh waktu di bawah ini adalah WITA (GMT+8)").addClass("notice-idn").appendTo($col12);
                var $engnotice         = $("<h6></h6>").text("All the showtime below are on WITA (GMT+8)").addClass("notice-eng").appendTo($col12);

                if(!date) return renderNullDate($col12);

                //set loader
                setLoader(true, $col12);
                
                requestSchedule(date, function(reply){

                    // unset loader
                    setLoader(false, $col12);

                    for(var i = 0; i < reply.length; i++){
                    
                        var row = reply[i];

                        
                        var $schedulePanel  = $("<div></div>").addClass("schedule-panel").appendTo($col12);
                        var $mainContent    = $("<div></div>").addClass("main-content").appendTo($schedulePanel);
                        var $filmCover      = $("<div></div>").addClass("film-cover")
                        .css("background-image", "url('" + row.movie.cover + "')")
                        .appendTo($mainContent);

                        var articleLink     = "/article/" + row.movie.code;
                        var $watchTrailer   = $("<a></a>").addClass("icon-watch-trailer")
                        .attr("href", articleLink).appendTo($filmCover);
                        
                        var $trailerIcon    = $("<img>").attr("src","/images/assets/btn-play.png").addClass("icon-watch-trailer-img").appendTo($watchTrailer);
    
                        var $scheduleContent= $("<div></div>").addClass("schedule-content").appendTo($mainContent);
                        var $scheduleHeader = $("<div></div>").addClass("schedule-header").appendTo($scheduleContent);
                        
                        var $filmTitle = $("<div></div>").addClass("film-title").appendTo($scheduleHeader);
                        
                        if(row.title.length <= TITLE_LIMIT){
                            $filmTitle.addClass("film-title")
                        }
                        else if(row.title.length >= TITLE_LIMIT && row.title.length < TITLE_LIMIT_VERY){
                            $filmTitle.addClass("film-title-long");
                        }
                        else if(row.title.length >= TITLE_LIMIT_VERY){
                            $filmTitle.addClass("film-title-very-long");
                        }
                        
                        var $filmDuration   = $("<div></div>").addClass("film-duration").appendTo($scheduleHeader);
                        var $filmDesc       = $("<div></div>").appendTo($scheduleHeader);

                        var filmDescClass   = row.title.length <= TITLE_LIMIT ? "film-description-padding" : "film-description"
                        $filmDesc.addClass(filmDescClass);

                        var $table          = $("<table></table>").appendTo($filmDesc);
    
                        var $scheduleFooter = $("<div></div>").addClass("schedule-footer").appendTo($scheduleContent)
                        var $studioLabel    = $("<div></div>").addClass("studio-label").appendTo($scheduleFooter);
    

    
                        var $title      = $("<a></a>").text(row.title).attr("href", "/article/" + row.movie.code).appendTo($filmTitle);
                        if(row.title.length >= TITLE_LIMIT) $title.addClass("long-title");

                        var $icon       = $("<i></i>").addClass("fa fa-clock-o").appendTo($filmDuration);
                        var res = isNaN(row.movie.duration);
                        if(res == false){
                            var $duration   = $("<span></span>").text(" " + row.movie.duration + " minutes").appendTo($filmDuration);
                        }
                        else{
                            var $tba        = $("<span></span>").text("TBA").appendTo($filmDuration);
                        }
                        
                        var $tr         = $("<tr></tr>").appendTo($table);
                        var $releaseDate= $("<td></td>").text("Release").addClass("emphasis").appendTo($tr);
                        var $firstTd    = $("<td></td>").text(row.movie.release_date).appendTo($tr);
                        
                        var $tr         = $("<tr></tr>").appendTo($table);
                        var $category   = $("<td></td>").text("Category").addClass("emphasis").appendTo($tr);
                        var $thertTd    = $("<td></td>").text(row.movie.genre.text).appendTo($tr);

                        var $tr         = $("<tr></tr>").appendTo($table);
                        var $genre      = $("<td></td>").text("Genre").addClass("emphasis").appendTo($tr);
                        var $secondTd   = $("<td></td>").text(row.movie.tags).appendTo($tr);
                          
                        var $tr         = $("<tr></tr>").appendTo($table);
                        var $htm        = $("<td></td>").text("HTM").addClass("emphasis").appendTo($tr);
                        var formated = accounting.formatMoney(row.htm, "Rp. ", 2, ".", ",");

                        var $fourtTd    = $("<td></td>").text(formated).appendTo($tr);
                        var $showtimelist= $("<div></div>").addClass("showtime-list").appendTo($scheduleFooter);
    
                        var studioUrl   = "/studio?id=" + row.location_id;
                        var $label      = $("<a></a>").attr("href", studioUrl).text("STUDIO " + row.studio).appendTo($studioLabel);
    
                        var $ul         = $("<div></div>").addClass("row ul-showtime").appendTo($showtimelist);
                        
                        for(var j = 0; j < row.show_time.length; j++){
                            var $li       = $("<div></div>").addClass("col-md-3 li-showtime").appendTo($ul);
                            var url         = "/user#!/user/seats/" + row.show_time[j].id;
                            $("<a></a>")
                            .attr("href", url)
                            .addClass("disabled showtime-denpasar")
                            .text(row.show_time[j].date).appendTo($li);    
                        }
                    }

                    if(reply.length <= 0){
                        var dt = moment(date).format("DD/MM/YYYY")
                        var $span = $("<span></span>").addClass("info main").text("Maaf tidak tidak tersedia jadwal untuk " + dt + "").appendTo($col12)
                        var $span = $("<span></span>").addClass("info sub").text("Sorry, there is no available schedule for " + dt + ". Please come back later").appendTo($col12)
                    }
                    
                });

            };

            requestOptions(function(reply){
                // TODO: Sementara, harus diganti ke popup alert error
                if(reply && reply.error) return console.log(reply);
                currentOptions          = reply;
                var defaultSelectedDate = reply.dates.length > 0 ? reply.dates[0].id : null;
                return render(defaultSelectedDate, reply);
            });

        }
    });

});