$(function () {
    $.widget("movimax.movie", {
        options: {
            name: null,
            date: new Date()
        },
        _create: function () {
            var opt = this.options;
            var $elm = this.element;
            var date = opt.date;
            var $tabOption, $tabButtonNP, $tabButtonCS;
            //NP:NOW PLAYING --- CS:COMING SOON
            var $nowPlaying, $headerNP, $lineHeaderNP, $columnNP, $containerNP, $rowNP, $itemsNP, $linkNP, $brickImageNP, $brickInfoNP, $brickInfoDetailNP, $headerMovieNP, $brickInfoOrderNP;
            var $comingSoon, $headerCS, $lineHeaderCS, $columnCS, $containerCS, $rowCS, $itemsCS, $linkCS, $brickImageCS, $brickInfoCS, $brickInfoDetailCS, $headerMovieCS, $brickInfoOrderCS;
            var $btnError;
            var $movieList;

            var requestMovies = function (type, callback) {
                var params      = {};
                params.type     = type;
                request("/guest/movie/showposter", params, callback);
            };

            var getMoviesNowPlaying = function () {
                requestMovies('nowplaying', function (reply) {
                    if (reply.error) {
                        $btnError = $("<button>").addClass("error").addClass('btn-block').appendTo($movieList);
                        $btnError.text('Click to Refresh.').appendTo($btnError);

                        return $btnError.click(function () {
                            getMoviesNowPlaying();
                        })
                    }
                    $movieList.html("");
                    $nowPlaying         = $("<div>").addClass("content-mobile-now-playing").appendTo($movieList);
                    $containerNP        = $("<div>").addClass("container").appendTo($nowPlaying);
                    $columnNP           = $("<div>").addClass("col-xs-12").appendTo($containerNP);
                    $headerNP           = $("<h3>").addClass('title-header-content').addClass('align-center').addClass('margin-top-30').appendTo($columnNP);
                    $headerNP.text('NOW PLAYING').appendTo($headerNP);
                    $lineHeaderNP       = $("<div>").addClass('line-header').appendTo($columnNP);
                    $rowNP              = $("<div>").addClass("row").appendTo($containerNP);
                    for (var i = 0; i < reply.length; i++) {
                        $itemsNP            = $("<div>").addClass("items").appendTo($rowNP);
                        $linkNP             = $("<a>").attr("href", "/article/" + reply[i].code).appendTo($itemsNP);
                        $brickImageNP       = $("<div>").addClass("brick-image").css("background-image", "url('"+ reply[i].cover +"')").appendTo($linkNP);
                        $brickInfoNP        = $("<div>").addClass("brick-info").appendTo($linkNP);
                        $brickInfoDetailNP  = $("<div>").addClass("brick-info-detail").addClass("align-center").appendTo($brickInfoNP);
                        $headerMovieNP      = $("<h5>").appendTo($brickInfoDetailNP);
                        $headerMovieNP.text(reply[i].title).appendTo($headerMovieNP);
                        $brickInfoOrderNP   = $("<div>").addClass("brick-info-order").appendTo($linkNP);
                    }
                });
            };
            var getMoviesUpComing = function () {
                requestMovies('upcoming', function (reply) {
                    if (reply.error) {
                        $btnError = $("<button>").addClass("error").addClass('btn-block').appendTo($movieList);
                        $btnError.text('Click to Refresh.').appendTo($btnError);

                        return $btnError.click(function () {
                            getMoviesUpComing();
                        })
                    }
                    $movieList.html("");

                    $comingSoon         = $("<div>").addClass("content-mobile-coming-soon").appendTo($movieList);
                    $containerCS        = $("<div>").addClass("container").appendTo($comingSoon);
                    $columnCS           = $("<div>").addClass("col-xs-12").appendTo($containerCS);
                    $headerCS           = $("<h3>").addClass('title-header-content').addClass('align-center').addClass('margin-top-30').appendTo($columnCS);
                    $headerCS.text('COMING SOON').appendTo($headerCS);
                    $lineHeaderCS       = $("<div>").addClass('line-header').appendTo($columnCS);
                    $rowCS              = $("<div>").addClass("row").appendTo($containerCS);
                    for (var i = 0; i < reply.length; i++) {
                        $itemsCS            = $("<div>").addClass("items").appendTo($rowCS);
                        $linkCS             = $("<a>").attr("href", "/article/" + reply[i].code).appendTo($itemsCS);
                        $brickImageCS       = $("<div>").addClass("brick-image").css("background-image", "url('"+ reply[i].cover +"')").appendTo($linkCS);
                        $brickInfoCS        = $("<div>").addClass("brick-info").appendTo($linkCS);
                        $brickInfoDetailCS  = $("<div>").addClass("brick-info-detail").addClass("align-center").appendTo($brickInfoCS);
                        $headerMovieCS      = $("<h5>").appendTo($brickInfoDetailCS);
                        $headerMovieCS.text(reply[i].title).appendTo($headerMovieCS);
                        $brickInfoOrderCS   = $("<div>").addClass("brick-info-order").appendTo($linkCS);
                    }
                });
            };

            $tabOption      = $("<div>").addClass("tab-option").appendTo($elm);
            $tabButtonNP    = $("<button>").addClass("btn").addClass("tab-button").addClass('btn-now-playing').addClass("active").appendTo($tabOption);
            $tabButtonNP.text('Now Playing').appendTo($tabButtonNP);
            $tabButtonCS    = $("<button>").addClass("btn").addClass("tab-button").addClass('btn-coming-soon').appendTo($tabOption);
            $tabButtonCS.text('Coming Soon').appendTo($tabButtonCS);

           

            $movieList      = $("<div>").appendTo($elm);
            
            $tabButtonNP.click(function () {
                getMoviesNowPlaying();
            });

            $tabButtonCS.click(function () {
                $("button").removeClass("active");
                getMoviesUpComing();
            });

            getMoviesNowPlaying();

        }
    });

});
