/**
 * Created by denny on 5/8/17.
 */

var slideout = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'padding': 256,
    'tolerance': 70
});

slideout.disableTouch();


// Toggle button
Date.prototype.addDays = function(days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
};

document.querySelector('.toggle-button').addEventListener('click', function () {
    slideout.toggle();
});

jQuery(function ($) {
    $("#schedule-page").schedule();

    $('#schedule-mobile').scheduleMovieMobile();
    var contentNowPlaying = false;

    $("#myInput").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#myList .row").filter(function() {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });

    function showNowPlaying() {
        if (contentNowPlaying) $('#content-mobile-now-playing').fadeOut('slow');
        else $('#content-mobile-coming-soon').fadeIn('slow');
    }

    function showComingSoon() {
        if (contentNowPlaying) $('#content-mobile-coming-soon').fadeOut('slow');
        else $('#content-mobile-now-playing').fadeIn('slow');
    }

    $('#content-mobile-coming-soon').hide();
    $("#btn-show-now-playing").click(function () {
        contentNowPlaying = true;
        showComingSoon();
    });
    $("#btn-show-coming-soon").click(function () {
        contentNowPlaying = false;
        showNowPlaying();
    });

    $('.bxslider').bxSlider({
        auto: true,
        autoControls: true,
        stopAutoOnClick: false,
        touchEnabled: false,
        pager: true,
        slideWidth: 600
      });

    $(".content-movie-menu").movie({});

    var swiper = new Swiper('.swiper-container', {
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2400,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: 'i.fa.fa-chevron-right.right',
            prevEl: 'i.fa.fa-chevron-left.left',
        },
    });

    setInterval(function(){
		var str = moment().tz('Asia/Makassar').format("dddd DD MMMM YYYY HH:mm:ss");
		$("#current-time-wita").text(str);
    }, 1000)
    
    $('.headline-slider-mobile').bxSlider({
        auto: true,
        tickerHover: true,
        captions: true,
        pause: 3000,
        controls: false,
        touchEnabled: false,
        pager: true,
        wrapperClass: "headline-wrapper",
        prevSelector : ".button-slide",
        nextSelector : ".button-slide"
    });
});


