$(function () {
    
    $.widget("movimax.scheduleMovieMobile", {
        options: {},
        selectedDate: null,
        _create: function () {
            var $elm = this.element;
            var self = this;

            // set tanggal
            this.selectedDate = new Date();

            var $div = $("<div></div>")
                .addClass("list-schedule")
                .appendTo($elm)

            this.requestSchedule(this.selectedDate, function(reply){
                self.renderShowtime($div, self.selectedDate, reply)
            })
            

        },
        
        requestSchedule: function (date, callback){
            var params = {};
            params.date = date;
            request('/api/showtime/showschedulebydate', params, function(reply){
                return callback(reply);
            })
        },
        
        renderShowtime: function ($parent, selectedDate, showtimes) {

            $parent.html("");

            var self = this;

            var $table = $("<table></table>")
                .attr('style', 'margin-bottom: 5px')
                .addClass("day")
                .appendTo($parent)
                
            var $tr = $("<tr></tr>")
                .appendTo($table)

            var $td = $("<td></td>").appendTo($tr)
            var $btnPrev = $("<a></a>").addClass("btn-control").appendTo($td);
            $("<i></i>").addClass("fa fa-chevron-circle-left").attr('style', 'font-size: 30px; width: 2%; padding-left: 5px;').appendTo($btnPrev);
                
            var $td = $("<td></td>")
                .attr('style', 'width: 96%; text-align: center;')
                .appendTo($tr)
            var dt = moment(selectedDate).format("dddd, DD MMM YYYY");
            $("<h6></h6>").addClass("today").text(dt).appendTo($td);

            var $td = $("<td></td>").appendTo($tr);
            var $btnNext = $("<a></a>").addClass("btn-control").appendTo($td);
            $("<i></i>").addClass("fa fa-chevron-circle-right").attr('style', 'font-size: 30px; padding-right: 5px; width: 2%;').appendTo($btnNext);

            $btnPrev.click(function(){
                selectedDate = selectedDate.addDays(-1);
                self.requestSchedule(selectedDate, function(reply){
                    self.renderShowtime($parent, selectedDate, reply);
                })
            });

            $btnNext.click(function(){
                selectedDate = selectedDate.addDays(1);
                self.requestSchedule(selectedDate, function(reply){
                    self.renderShowtime($parent, selectedDate, reply);
                })
            });
            
            if(showtimes.length <= 0){
                // tambahkan halaman kosong

                // BAHASA INDONESIA
                var $table = $("<table></table>")
                    .attr("style","width: 97%; margin-top: 20px; height: 95px; margin-left: 5px;margin-right: 5px")
                    .addClass("alert alert-danger empty-page")
                    .appendTo($parent)
                var $tr = $("<tr></tr>")
                    .appendTo($table)
                var $td = $("<td></td>")
                    .attr("style","text-align: center;")
                    .appendTo($tr)
                var msgId = $("<span></span>")
                    .addClass("msg-no-schedule id")
                    .text("Maaf, Jadwal masih belum tersedia")
                    .appendTo($td)
                var br = $("<br>")
                    .appendTo(msgId)
                // BAHASA INGGRIS
                var msgEn = $("<span></span>")
                    .addClass("msg-no-schedule en")
                    .text("Sorry, there is no schedules available")
                    .appendTo($td)
            }

            // TABLE JADWAL
            for(var i = 0; i < showtimes.length; i++){
                var $div = $("<div></div>")
                .addClass("schedule-mobile-now-playing")
                .appendTo($parent)
                var $table = $("<table></table>")
                    .appendTo($div)

                var $tr = $("<tr></tr>")
                    .appendTo($table)
                    
                var $td = $("<td></td>")
                    .addClass("title-movie")
                    .text(showtimes[i].film)
                    .attr('colspan','2')
                    .appendTo($tr)

                var $td = $("<td></td>")
                    .appendTo($tr)
            
                    
                var $tr = $("<tr></tr>")
                    .appendTo($table)

                var $td = $("<td></td>")
                    .attr('width', '20%')
                    .addClass('divdate')
                    .appendTo($tr)

                var $div = $("<div></div>")
                    .addClass("studio")
                    .appendTo($td)

                var studio_number = showtimes[i].studio;

                $("<span></span>")
                    .addClass("studio")
                    .text(studio_number)
                    .appendTo($div)

                ////////////////////////// TD UNTUK JAM DISEBELAH KANAN //////////////////////////////
                var $td = $("<td></td>")
                    .appendTo($tr)
                    .addClass('divshowtime')

                var $showtime = $("<div></div>")
                    .addClass("showtime")
                    .appendTo($td)

                var $ul = $("<ul></ul>")
                    .addClass("ul-showtime")
                    .appendTo($showtime)
                
                
                for(var j = 0; j < showtimes[i].times.length; j++){
                    var show = showtimes[i].times[j].text
                    var $li = $("<li></li>")
                        .addClass("li-showtime")
                        .appendTo($ul)
                    $("<a></a>").attr('href','/login').addClass("disabled showtime-denpasar").text(show).appendTo($li)
                    
                }
                
            }
            
            var $linkMore = $("<a></a>")
            .attr('href', '/schedule')
            .addClass("btn btn-secondary view-more")
            .text("JADWAL SELENGKAPNYA")
            .appendTo($parent)
        },
        
    });

})