/**
 * Created by gigabyte on 6/19/17.
 */


    window.request = function(){
    var pth, dt, callback;
    if(arguments.length == 3){
        pth = arguments[0];
        dt = arguments[1];
        callback = arguments[2];
    } else if(arguments.length == 2){
        pth = arguments[0];
        callback = arguments[1];
    }
    var z = (new Date()).toString();
    var p = CryptoJS.AES.encrypt(pth, window.gid).toString();
    var b = CryptoJS.AES.encrypt(JSON.stringify(dt), window.gid).toString();
    
    var params  = {};
    params.p    = p;
    params.b    = b;
    params.z    = CryptoJS.AES.encrypt(z, window.gid).toString();
    
    $.get('/encguest', params, callback);

};
window.gid  =  CryptoJS.MD5("guest").toString();


function clickAndDisable(link) {
	link.onclick = function(event) {
		event.preventDefault();
    }
}   
